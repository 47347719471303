import React, { useState, cloneElement } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Datagrid,
  TextField,
  ChipField,
  FunctionField,
  Filter,
  NumberInput,
  useDataProvider,
  useNotify,
  useRefresh,
  TopToolbar,
  CreateButton,
  useListContext,
  sanitizeListRestProps,
} from 'react-admin';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import { makeStyles } from '@material-ui/core/styles';
import { Pagination, LinearProgressField } from '../../components';
import { formatDatetime } from '../../utils';
import { marginZeroStyles } from '../../constants';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
}));

const ListFilter = props => {
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="Id" source="id" alwaysOn />
    </Filter>
  );
};

const ListActions = ({ className, filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, hasCreate, basePath, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasCreate ? <CreateButton basePath={basePath} /> : null}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

const InterruptButtonField = ({ record = {} }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const [isLoading, setIsLoading] = useState(false);

  const applyTransition = (id, name, params = {}) => {
    setIsLoading(true);
    dataProvider
      .query(`mass_sending_processes/${id}/apply_transition`, {
        method: 'POST',
        body: JSON.stringify({ name, params }),
      })
      .then(() => refresh())
      .catch(error => notify(`Error: ${error.message}`, 'error'))
      .finally(() => setIsLoading(false));
  };

  return (
    <IconButton
      color="primary"
      disabled={isLoading || !record.enabled_transitions || record.enabled_transitions.indexOf('interrupt') === -1}
      onClick={() => applyTransition(record.id, 'interrupt')}>
      <CancelIcon />
    </IconButton>
  );
};

InterruptButtonField.propTypes = {
  record: PropTypes.shape({
    enabled_transitions: PropTypes.array,
    id: PropTypes.number,
  }),
};

export default props => (
  <List
    pagination={<Pagination />}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    filters={<ListFilter />}
    actions={<ListActions />}
    {...props}>
    <Datagrid>
      <TextField source="id" />
      <ChipField size="small" source="state" />
      <ChipField size="small" source="audience_id" />
      <ChipField size="small" source="transmitter_id" />
      <TextField label="Template" source="template_key" />
      <LinearProgressField source="progress" />
      <FunctionField
        label="Scheduled at"
        source="scheduled_at"
        render={(record, key) => (record[key] ? formatDatetime(record[key]) : null)}
      />
      <FunctionField
        label="Finished at"
        source="finished_at"
        render={(record, key) => (record[key] ? formatDatetime(record[key]) : null)}
      />
      <InterruptButtonField />
    </Datagrid>
  </List>
);
