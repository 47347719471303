import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  BooleanInput,
  Create,
  FormDataConsumer,
  FormWithRedirect,
  maxLength,
  required,
  SaveButton,
  SelectInput,
  TextInput,
  useDataProvider,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Typography,
  Box,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { Autocomplete } from '../../components';
import { time as timeValidator } from '../../utils';
import { useHandbook, useEmailSources } from '../../hooks';
import { DAYS_MODE_DOW, DAYS_MODE_DOM, WEEK_DAYS, MONTH_DAYS, PHONE_CONTACT, TRANSMITTER } from '../../constants';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  my2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  mb2: {
    marginBottom: theme.spacing(2),
  },
  mt1: {
    marginTop: theme.spacing(1),
  },
  domList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gridTemplateColumns: 'repeat',
  },
  domItem: {
    flexBasis: '80px',
    flexShrink: 0,
  },
}));

const FormWrapper = ({ save, ...props }) => {
  const [params, setParams] = useState([]);
  const [daysMode, setDaysMode] = useState(DAYS_MODE_DOW);
  const [usePhoneBook, setUsePhoneBook] = useState(false);
  const [cmProfilesList, setCmProfilesList] = useState([]);
  const [weekDays, setWeekDays] = useState([]);
  const [monthDays, setMonthDays] = useState([]);
  const [errors, setErrors] = useState({});
  const [isCmSelected, setIsCmSelected] = useState(false);
  const [templateFilter, setTemplateFilter] = useState({ is_enabled: true });
  const { isEmailSelected, handleFormChange } = useEmailSources();

  const dataProvider = useDataProvider();
  const classes = useStyles();

  const { choices: contactRelationshipChoices } = useHandbook('phone_relations');
  const { choices: phoneTypeChoices } = useHandbook('phone_types');
  const { choices: phoneSourceChoices } = useHandbook('phone_sources');
  const phoneStatusChoices = [
    { id: '', name: 'Any' },
    { id: 'active', name: 'Active' },
    { id: 'inactive', name: 'Inactive' },
  ];
  const phoneValidationChoices = [
    { id: '', name: 'Any' },
    { id: 'MOBILE', name: 'Mobile' },
    { id: 'LANDLINE', name: 'Landline' },
    { id: 'VOIP', name: 'Voip' },
    { id: 'OTHER', name: 'Other' },
    { id: 'PREPAID', name: 'Prepaid' },
    { id: 'INVALID', name: 'Invalid' },
  ];

  useEffect(() => {
    dataProvider
      .query('cm_profiles?page=1&items_per_page=30&is_pending=false', { method: 'GET' })
      .then(({ data }) => setCmProfilesList(data));
  }, [dataProvider]);

  const onChangeWeekDay = id => {
    const element = weekDays.includes(id);
    if (element) {
      setWeekDays(weekDays.filter(i => i !== id));
    } else {
      setWeekDays([...weekDays, id]);
    }
  };

  const onChangeMonthDay = id => {
    const element = monthDays.includes(id);
    if (element) {
      setMonthDays(monthDays.filter(i => i !== id));
    } else {
      setMonthDays([...monthDays, id]);
    }
  };

  const addItem = () => {
    setParams([
      ...params,
      {
        id: Date.now(),
        type: null,
        relation: null,
        source: null,
        status: null,
        validation: null,
      },
    ]);
  };

  const removeItem = id => {
    setParams([...params.filter(item => item.id !== id)]);
  };
  const showOptions = value => {
    setUsePhoneBook(value);
    setParams([]);
  };
  const setSelectValue = (id, type, value) => {
    setParams([
      ...params.map(item => {
        if (item.id === id && type === 'type' && value !== 'contact_person') {
          item[type] = value;
          item.relation = null;
        } else if (item.id === id) {
          item[type] = value;
        }
        return item;
      }),
    ]);
  };

  return (
    <FormWithRedirect
      save={(
        {
          is_enabled,
          audience_id,
          name,
          alpha_name,
          start_time,
          transmitter_id,
          phone_contact,
          scheduled_at,
          template_id,
          template_key,
          channel,
          email_source,
        },
        ...rest
      ) => {
        const validationErrors = {};
        if (daysMode === DAYS_MODE_DOW && weekDays.length === 0) {
          validationErrors.week_days = 'Week days should not be empty';
        }
        if (daysMode === DAYS_MODE_DOM && monthDays.length === 0) {
          validationErrors.month_days = 'Month days should not be empty';
        }

        const channelValue = isEmailSelected ? email_source : channel;

        if (Object.keys(validationErrors).length > 0) {
          setErrors(validationErrors);
          return;
        }

        const payload = {
          is_enabled,
          name,
          audience_id,
          transmitter_id,
          phone_contact,
          channel: channelValue,
          scheduled_at,
          start_time,
          params: {
            week_days: daysMode === DAYS_MODE_DOW ? weekDays : [],
            month_days: daysMode === DAYS_MODE_DOM ? monthDays : [],
            process: {
              use_phone_book: usePhoneBook,
              alpha_name,
              phone_book_filters: params,
            },
          },
        };

        if (transmitter_id === 'cm') {
          payload.template_key = template_key;
          payload.template = null;
        } else {
          payload.template_id = template_id;
          payload.template_key = null;
        }

        save(...[payload, ...rest]);
      }}
      {...props}
      render={formProps => {
        return (
          <form>
            <Grid container justifyContent="center" spacing={4}>
              <Grid item xs={12} sm={8}>
                <Paper className={classes.paper}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={10}>
                      <Typography variant="h6" gutterBottom={false}>
                        Create mass sending campaign
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <BooleanInput label="Enabled" source="is_enabled" />
                    </Grid>
                  </Grid>
                  <Divider className={classes.my2} />
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextInput source="name" validate={[required(), maxLength(255)]} fullWidth />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextInput
                          helperText="Format: hh:mm:ss"
                          source="start_time"
                          validate={[required(), value => timeValidator(value)]}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Autocomplete
                          resource="mass_sending_audiences"
                          source="audience_id"
                          optionValueProp="id"
                          optionLabelProp="name"
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SelectInput
                          onChange={e => {
                            const registeredFields = formProps.form.getRegisteredFields();
                            if (registeredFields.includes('template_id')) {
                              formProps.form.change('template_id', null);
                              formProps.form.resetFieldState('template_id');
                            }
                            if (registeredFields.includes('template_key')) {
                              formProps.form.change('template_key', null);
                              formProps.form.resetFieldState('template_key');
                            }
                            if (registeredFields.includes('channel')) {
                              formProps.form.change('channel', null);
                              formProps.form.resetFieldState('channel');
                            }
                            setIsCmSelected(e.target.value === 'cm');
                            handleFormChange(e, formProps.form);
                            if (e.target.value === 'cm') {
                              setTemplateFilter({ is_enabled: true });
                            } else if (e.target.value === 'mailer') {
                              setTemplateFilter(prev => ({ ...prev, category: 'Email' }));
                            } else if (e.target.value === 'firebase_cloud_messaging') {
                              setTemplateFilter(prev => ({ ...prev, category: 'Push' }));
                            } else {
                              setTemplateFilter(prev => ({ ...prev, category: 'SMS' }));
                            }
                          }}
                          source="transmitter_id"
                          optionText="name"
                          choices={TRANSMITTER}
                          validate={[required()]}
                          fullWidth
                        />
                      </Grid>
                      {isEmailSelected && (
                        <Grid item xs={12} sm={6}>
                          <Autocomplete
                            resource="email_outbox_addresses"
                            source="email_source"
                            optionValueProp="email"
                            optionLabelProp="email"
                          />
                        </Grid>
                      )}
                      {isCmSelected ? (
                        <FormDataConsumer>
                          {() => (
                            <>
                              <Grid item xs={12} sm={6}>
                                <Autocomplete
                                  resource="cm_templates"
                                  source="template_key"
                                  optionValueProp="template_id"
                                  optionLabelProp="name"
                                  label="Template"
                                  filter={{ is_active: true, is_pending: false }}
                                  required
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <SelectInput
                                  helperText={false}
                                  source="channel"
                                  validate={[required()]}
                                  choices={cmProfilesList}
                                  optionText="phone_number"
                                  optionValue="channel_id"
                                  fullWidth
                                />
                              </Grid>
                            </>
                          )}
                        </FormDataConsumer>
                      ) : (
                        <Grid item xs={12} sm={6}>
                          <Autocomplete
                            resource="templates"
                            source="template_id"
                            optionValueProp="id"
                            optionLabelProp="key"
                            label="Template"
                            filter={templateFilter}
                            required
                          />
                        </Grid>
                      )}
                      <Grid item xs={12} sm={6}>
                        <SelectInput
                          allowEmpty
                          source="phone_contact"
                          choices={PHONE_CONTACT}
                          label="Phone results"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <RadioGroup row className={classes.mb2}>
                          <FormControlLabel
                            value={DAYS_MODE_DOW}
                            control={<Radio />}
                            label="Days of week"
                            checked={daysMode === DAYS_MODE_DOW}
                            onChange={() => {
                              setDaysMode(DAYS_MODE_DOW);
                              setMonthDays([]);
                              setErrors({});
                            }}
                          />

                          <FormControlLabel
                            value={DAYS_MODE_DOM}
                            control={<Radio />}
                            label="Days of month"
                            checked={daysMode === DAYS_MODE_DOM}
                            onChange={() => {
                              setDaysMode(DAYS_MODE_DOM);
                              setWeekDays([]);
                              setErrors({});
                            }}
                          />
                        </RadioGroup>

                        {daysMode === DAYS_MODE_DOW && (
                          <>
                            <div>
                              {WEEK_DAYS.map((day, idx) => (
                                <FormControlLabel
                                  key={day}
                                  control={
                                    <Checkbox
                                      onChange={() => onChangeWeekDay(idx + 1)}
                                      checked={weekDays.includes(idx + 1)}
                                    />
                                  }
                                  label={day}
                                />
                              ))}
                            </div>
                            <div>
                              {errors.week_days && (
                                <Typography color="error" variant="caption">
                                  {errors.week_days}
                                </Typography>
                              )}
                            </div>
                          </>
                        )}

                        {daysMode === DAYS_MODE_DOM && (
                          <>
                            <div className={classes.domList}>
                              {MONTH_DAYS.map(({ value, label }) => (
                                <div key={value} className={classes.domItem}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        onChange={() => onChangeMonthDay(value)}
                                        checked={monthDays.includes(value)}
                                      />
                                    }
                                    label={label}
                                  />
                                </div>
                              ))}
                            </div>
                            <div>
                              {errors.month_days && (
                                <Typography color="error" variant="caption">
                                  {errors.month_days}
                                </Typography>
                              )}
                            </div>
                          </>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="caption" gutterBottom={false}>
                          IMPORTANT! Please note that the sending campaign will not work until the next day at the
                          earliest! For example, if today is Monday and the campaign is now set to send on Monday, then
                          it will not send today.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Divider className={classes.my2} />
                  <FormControlLabel
                    control={<Checkbox value={usePhoneBook} onChange={() => showOptions(!usePhoneBook)} />}
                    label="Use alternative contacts"
                  />
                  {usePhoneBook ? (
                    <Fragment>
                      <Button onClick={addItem} color="primary" variant="contained">
                        add
                      </Button>
                      {params.map(item => (
                        <Grid container alignItems={'center'} spacing={2} key={item.id}>
                          <Grid item xs={12} sm={4}>
                            <SelectInput
                              helperText={false}
                              label={'Contact source'}
                              source={`contact_source->${item.id}`}
                              validate={[required()]}
                              choices={phoneSourceChoices}
                              onChange={e => setSelectValue(item.id, 'source', e.target.value)}
                              value={item.source}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <SelectInput
                              helperText={false}
                              label={'Contact type'}
                              source={`contact_type->${item.id}`}
                              validate={[required()]}
                              choices={phoneTypeChoices}
                              onChange={e => setSelectValue(item.id, 'type', e.target.value)}
                              value={item.type}
                              fullWidth
                            />
                          </Grid>
                          {item.type === 'contact_person' ? (
                            <Grid item xs={12} sm={3}>
                              <SelectInput
                                emptyValue={null}
                                helperText={false}
                                validate={[required()]}
                                label={'Contact relation'}
                                source={`contact_relation->${item.id}`}
                                choices={contactRelationshipChoices}
                                value={item.relation}
                                onChange={e => setSelectValue(item.id, 'relation', e.target.value)}
                                fullWidth
                              />
                            </Grid>
                          ) : null}
                          <Grid item xs={12} sm={4}>
                            <SelectInput
                              helperText={false}
                              label={'Phone status'}
                              source={`contact_status->${item.id}`}
                              choices={phoneStatusChoices}
                              onChange={e => setSelectValue(item.id, 'status', e.target.value)}
                              value={item.status}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <SelectInput
                              helperText={false}
                              label={'Phone validation'}
                              source={`contact_validation->${item.id}`}
                              choices={phoneValidationChoices}
                              onChange={e => setSelectValue(item.id, 'validation', e.target.value)}
                              value={item.validation}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <Button onClick={() => removeItem(item.id)} color="primary" variant="contained">
                              <Delete />
                            </Button>
                          </Grid>
                          <Divider className={classes.my2} />
                        </Grid>
                      ))}
                    </Fragment>
                  ) : null}
                  <Divider className={classes.my2} />
                  <SaveButton
                    label="Submit"
                    redirect="list"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                </Paper>
              </Grid>
            </Grid>
          </form>
        );
      }}
    />
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
};

const MassSending = props => (
  <Create component="div" {...props}>
    <FormWrapper />
  </Create>
);
export default MassSending;
