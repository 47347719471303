import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNotify, useDataProvider, usePermissions } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import DetailsIcon from '@material-ui/icons/Details';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { formatDate, formatTime, openIntoNewTab } from '../../utils';
import { CollapsedText } from '../collapsed_text/CollapsedText';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
}));

export const MoneyTransferTable = ({ loanId, refreshedAt, onTransition }) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { permissions = [] } = usePermissions();
  const [moneyTransfers, setMoneyTransfers] = useState();
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(30);
  const [transmissionLogsId, setTransmissionLogsId] = useState(null);
  const [transmissionLogs, setTransmissionLogs] = useState([]);
  const [transmissionLogsMenuAnchorEl, setTransmissionLogsMenuAnchorEl] = useState(null);
  const isTransmissionLogsMenuOpened = Boolean(transmissionLogsMenuAnchorEl);

  useEffect(() => {
    dataProvider
      .getList('money_transfers', {
        filter: { 'loan.id': loanId },
        pagination: { page, perPage },
        sort: { field: 'id', order: 'DESC' },
      })
      .then(({ data, total }) => {
        setMoneyTransfers(data);
        setTotal(total);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, loanId, refreshedAt, page, perPage]);

  if (loading) return <CircularProgress />;
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }
  if (!Array.isArray(moneyTransfers)) return null;

  const openTransmissionLogsMenu = (anchorEl, id, logs) => {
    setTransmissionLogsId(id);
    setTransmissionLogs(logs);
    setTransmissionLogsMenuAnchorEl(anchorEl);
  };

  const closeTransmissionLogsMenu = () => {
    setTransmissionLogsMenuAnchorEl(null);
    setTransmissionLogs([]);
    setTransmissionLogsId(null);
  };

  const handleTransmissionLogsMenuClick = log => {
    const id = transmissionLogsId;
    closeTransmissionLogsMenu();
    dataProvider
      .downloadFile(`money_transfers/${id}/transmission_log/${log}`)
      .then(({ data: objUrl }) => openIntoNewTab(objUrl))
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  return (
    <div className={classes.fullWidth}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Gateway</TableCell>
            <TableCell>State</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Status code</TableCell>
            <TableCell>Error</TableCell>
            <TableCell>Created at</TableCell>
            <TableCell>Updated at</TableCell>
            <TableCell>Scheduled at</TableCell>
            <TableCell>Finished at</TableCell>
            <TableCell>Triggered by</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {moneyTransfers.map(row => (
            <TableRow key={row.id}>
              <TableCell>{row.id}</TableCell>
              <TableCell>
                <Chip size="small" label={row.gateway} />
              </TableCell>
              <TableCell>
                <Chip size="small" label={row.state} />
              </TableCell>
              <TableCell>
                <Chip size="small" label={row.status} />
              </TableCell>
              <TableCell>{row.result_status_code && <Chip size="small" label={row.result_status_code} />}</TableCell>
              <TableCell>
                <CollapsedText text={row.error} />
              </TableCell>
              <TableCell>
                {row.created_at && (
                  <>
                    {formatDate(row.created_at)}
                    <br />
                    {formatTime(row.created_at)}
                  </>
                )}
              </TableCell>
              <TableCell>
                {row.updated_at && (
                  <>
                    {formatDate(row.updated_at)}
                    <br />
                    {formatTime(row.updated_at)}
                  </>
                )}
              </TableCell>
              <TableCell>
                {row.scheduled_at && (
                  <>
                    {formatDate(row.scheduled_at)}
                    <br />
                    {formatTime(row.scheduled_at)}
                  </>
                )}
              </TableCell>
              <TableCell>
                {row.finished_at && (
                  <>
                    {formatDate(row.finished_at)}
                    <br />
                    {formatTime(row.finished_at)}
                  </>
                )}
              </TableCell>
              <TableCell>
                {row.triggered_by_admin_id && row.triggered_by_admin_username
                  ? row.triggered_by_admin_username.concat('#').concat(row.triggered_by_admin_id)
                  : 'system'}
              </TableCell>
              <TableCell align="right">
                <IconButton
                  color="primary"
                  size="small"
                  disabled={row.transmission_logs.length === 0 || permissions.indexOf('CAN_MONEY_TRANSFER_EDIT') === -1}
                  onClick={e => openTransmissionLogsMenu(e.currentTarget, row.id, row.transmission_logs)}>
                  <DetailsIcon />
                </IconButton>
                <IconButton
                  color="primary"
                  size="small"
                  disabled={
                    row.enabled_transitions.indexOf('interrupt') === -1 ||
                    permissions.indexOf('CAN_MONEY_TRANSFER_EDIT') === -1
                  }
                  onClick={() => onTransition(row.id, 'interrupt')}>
                  <CancelIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={e => {
          setPerPage(parseInt(e.target.value, 10));
          setPage(1);
        }}
      />
      <Menu
        anchorEl={transmissionLogsMenuAnchorEl}
        keepMounted
        open={isTransmissionLogsMenuOpened}
        onClose={closeTransmissionLogsMenu}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
          },
        }}>
        {transmissionLogs.map((option, idx) => (
          <MenuItem key={idx} onClick={() => handleTransmissionLogsMenuClick(option)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

MoneyTransferTable.propTypes = {
  loanId: PropTypes.number,
  refreshedAt: PropTypes.number,
  onTransition: PropTypes.func,
};
