import React from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AddUserTagsInput from './AddUserTagsInput';
import { useStyles } from '../styles';

const UserTags = ({ userId, tags, editable }) => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  const handleDeleteTag = tagId => {
    dataProvider
      .query(`users/${userId}/remove_tag`, {
        method: 'POST',
        body: JSON.stringify({ tag_id: tagId }),
      })
      .then(() => {
        notify('Tag removed from user', 'success');
        refresh();
      })
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  return (
    <>
      <Box className={classes.tagsGrid} paddingTop="1rem">
        {tags.length ? (
          tags.map(tag =>
            editable ? (
              <Chip
                key={tag.id}
                size="small"
                label={tag.name}
                onDelete={() => handleDeleteTag(tag.id)}
                color="primary"
              />
            ) : (
              <Chip key={tag.id} size="small" label={tag.name} color="primary" />
            ),
          )
        ) : (
          <Typography variant="body2">No tags added</Typography>
        )}
      </Box>
      {editable && (
        <Box className={classes.tagsGrid} paddingTop="0.5rem" paddingBottom="1rem">
          <AddUserTagsInput userId={userId} selectedTags={tags} />
        </Box>
      )}
    </>
  );
};

UserTags.propTypes = {
  userId: PropTypes.number,
  tags: PropTypes.array,
  editable: PropTypes.bool,
};

export default UserTags;
