import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify } from 'react-admin';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Chip from '@material-ui/core/Chip';
import { formatCurrency, formatDatetime } from '../../../utils';

const IncomesList = ({ loanId, refreshedAt, onTransition, isTransitionsDisabled }) => {
  const [incomes, setIncomes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const notify = useNotify();
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider
      .query(`loans/${loanId}/incomes`, { method: 'GET' })
      .then(({ data }) => setIncomes(data))
      .catch(error => setError(error))
      .finally(() => setLoading(false));
  }, [dataProvider, loanId, refreshedAt]);

  if (loading) return <CircularProgress />;
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>State</TableCell>
          <TableCell>Gateway</TableCell>
          <TableCell align="right">Amount</TableCell>
          <TableCell>Payment type</TableCell>
          <TableCell>Received at</TableCell>
          <TableCell>Received from client at</TableCell>
          <TableCell>Processed at</TableCell>
          <TableCell>Extension by</TableCell>
          <TableCell>Paid via</TableCell>
          <TableCell align="right">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {incomes.map(row => (
          <TableRow key={row.id}>
            <TableCell>{row.id}</TableCell>
            <TableCell>
              <Chip size="small" label={row.state} />
            </TableCell>
            <TableCell>{row.gateway}</TableCell>
            <TableCell align="right">{formatCurrency(row.amount)}</TableCell>
            <TableCell>{row.payment_type}</TableCell>
            <TableCell>{formatDatetime(row.received_at)}</TableCell>
            <TableCell>{formatDatetime(row.received_from_client_at)}</TableCell>
            <TableCell>{row.processed_at && formatDatetime(row.processed_at)}</TableCell>
            <TableCell>{row.extension_activated_by || '---'}</TableCell>
            <TableCell>{row.bank_sender_name}</TableCell>
            <TableCell align="right">
              <ButtonGroup size="small" variant="contained" color="primary" disabled={isTransitionsDisabled}>
                {row.enabled_transitions.includes('cancel') && (
                  <Button onClick={() => onTransition(row.id, 'cancel')}>Cancel</Button>
                )}
                {row.enabled_transitions.includes('reset') && (
                  <Button onClick={() => onTransition(row.id, 'reset')}>Reset</Button>
                )}
              </ButtonGroup>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

IncomesList.propTypes = {
  loanId: PropTypes.number,
  refreshedAt: PropTypes.number,
  onTransition: PropTypes.func,
  isTransitionsDisabled: PropTypes.bool,
};

export default IncomesList;
