import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  ChipField,
  TopToolbar,
  CreateButton,
  useListContext,
  sanitizeListRestProps,
  BooleanField,
} from 'react-admin';
import { Pagination, Call } from '../../components';
import { formatPhoneNumber } from '../../utils';

const ListActions = ({ className, filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, hasCreate, basePath, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasCreate ? <CreateButton basePath={basePath} /> : null}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

const ExternalAgenciesList = props => (
  <List
    pagination={<Pagination />}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    actions={<ListActions />}
    {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" sortable={false} />
      <TextField source="name" sortable={false} />
      <ChipField source="status" size="small" sortable={false} />
      <TextField source="email" sortable={false} />
      <FunctionField
        source="phone"
        sortable={false}
        render={(record, key) =>
          record[key] && (
            <Call userId={record.id} userPhone={record.phone}>
              {formatPhoneNumber(record[key])}
            </Call>
          )
        }
      />
      <BooleanField source="is_payments_allowed" looseValue sortable={false} label="Payments allowed" />
      <TextField source="details" sortable={false} />
    </Datagrid>
  </List>
);

export default ExternalAgenciesList;
