import React, { useState, useEffect } from 'react';
import { useField } from 'react-final-form';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineTwoTone from '@material-ui/icons/AddCircleOutlineTwoTone';
import RemoveCircleOutlineTwoTone from '@material-ui/icons/RemoveCircleOutlineTwoTone';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';
import { AUDIENCE_FILTER_CONDITION_CHOICES, AUDIENCE_FILTER_INCLUDE_CONDITION_CHOICES } from '../../constants';

const useStyles = makeStyles(() => ({
  filterContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'center',
    marginBottom: 10,
  },
  ml: {
    marginLeft: 10,
  },
  btnGroup: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
  select: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export const OperationSignFilter = ({ id, title, filter, form }) => {
  const [inputList, setInputList] = useState(
    filter?.values
      ? filter?.values?.map((item, index) => ({
          condition: item.operation_sign,
          include: item.include || 'include',
          value: item.number,
          id: index,
        }))
      : [{ include: 'include', condition: '==', value: '', id: 0 }],
  );
  const classes = useStyles();
  const { meta } = useField(id);

  useEffect(() => {
    if (form) {
      inputList.forEach((item, index) => {
        form.change(`${id}.values[${index}].include`, item.include);
        form.change(`${id}.values[${index}].operation_sign`, item.condition);
        form.change(`${id}.values[${index}].number`, item.value);
      });
    }
  }, [form, inputList, id]);

  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);
    form.change(
      `${id}.values`,
      list.map(item => ({ operation_sign: item.condition, include: item.include, number: item.value })),
    );
    setInputList(list);
  };

  const handleAddClick = () => {
    setInputList([...inputList, { include: 'include', condition: '==', value: '', id: inputList.length + 1 }]);
  };

  return (
    <>
      <Divider />
      <Box p={2} className={classes.filterContainer}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              defaultChecked={filter?.active}
              onChange={e => form.change(`${id}.active`, e.target.checked ? [id] : [])}
            />
          }
          label={title}
        />
        {inputList.map((x, i) => {
          return (
            <Box key={i} className={classes.inputContainer}>
              <Box className={classes.select}>
                <FormControl style={{ minWidth: 120 }} size="small">
                  <Select
                    onChange={e => {
                      const newInputList = inputList.map((item, index) =>
                        i === index ? { ...item, include: e.target.value } : item,
                      );
                      setInputList(newInputList);
                      form.change(`${id}.values[${i}].include`, e.target.value);
                    }}
                    variant="filled"
                    label="Include"
                    value={x.include}>
                    {AUDIENCE_FILTER_INCLUDE_CONDITION_CHOICES.map(i => (
                      <MenuItem key={i.id} value={i.id}>
                        {i.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl style={{ minWidth: 80 }} size="small">
                  <Select
                    onChange={e => {
                      const newInputList = inputList.map((item, index) =>
                        i === index ? { ...item, condition: e.target.value } : item,
                      );
                      setInputList(newInputList);
                      form.change(`${id}.values[${i}].operation_sign`, e.target.value);
                    }}
                    variant="filled"
                    label="Condition"
                    value={x.condition}>
                    {AUDIENCE_FILTER_CONDITION_CHOICES.map(i => (
                      <MenuItem key={i.id} value={i.id}>
                        {i.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  onChange={e => {
                    const newInputList = inputList.map((item, index) =>
                      i === index ? { ...item, value: e.target.value } : item,
                    );
                    setInputList(newInputList);
                    form.change(`${id}.values[${i}].number`, e.target.value);
                  }}
                  className={classes.ml}
                  type="number"
                  label="Value"
                  size="small"
                  variant="filled"
                  value={x.value}
                  fullWidth
                  error={meta.touched && meta.error && x.value === ''}
                />
              </Box>
              <Box className={classes.btnGroup}>
                {inputList.length !== 1 && (
                  <IconButton color="primary" onClick={() => handleRemoveClick(i)}>
                    <RemoveCircleOutlineTwoTone />
                  </IconButton>
                )}
                {inputList.length - 1 === i && (
                  <IconButton color="primary" onClick={handleAddClick}>
                    <AddCircleOutlineTwoTone />
                  </IconButton>
                )}
              </Box>
            </Box>
          );
        })}
        {meta.touched && meta.error && (
          <FormHelperText style={{ textAlign: 'center' }} error={meta.touched && meta.error}>
            {meta.error}
          </FormHelperText>
        )}
      </Box>
    </>
  );
};

OperationSignFilter.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  filter: PropTypes.shape({
    active: PropTypes.bool,
    values: PropTypes.array,
  }),
  form: PropTypes.shape({
    change: PropTypes.func,
  }),
};

OperationSignFilter.defaultProps = {
  filter: {},
};
