import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useDataProvider, useNotify, usePermissions, useRedirect } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import { DateTimePicker } from '@material-ui/pickers';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ReactComponent as PhoneDisabledIcon } from '../../img/canceled_call.svg';
import { ReactComponent as WhatsAppIcon } from '../../img/cancelled_whatsapp.svg';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { formatDate, formatPhoneNumber, noop, timezone } from '../../utils';
import { useCollectionGroups, useHandbook } from '../../hooks';
import { DraggableDialog } from './DraggableDialog';

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
    margin: '0 0 8px',
  },

  modalTitle: {
    cursor: 'move',
  },

  modalHorizontalPadding: {
    paddingLeft: 12,
    paddingRight: 12,
  },

  colorTransparent: {
    color: 'transparent',
  },

  nonClickableBackdrop: {
    pointerEvents: 'none',

    '& .MuiPaper-root': {
      pointerEvents: 'auto',
    },
  },
  p0: {
    padding: 0,
  },
  withoutShadows: {
    boxShadow: 'none',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: 8,
  },
}));

function isPromiseOfPayment(name) {
  return /promise/imu.test(name) && /pay/imu.test(name);
}

const CollectionCallFormDialogTypeRecursiveInput = ({
  contactsTree,
  onChange,
  onStepChange,
  error,
  userHasActivePromise,
  depth,
  phoneNumber,
  clickToCallPhone,
  noAnswer,
  noAnswerType,
}) => {
  const classes = useStyles();
  const location = useLocation();

  const [value, setValue] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const contact = contactsTree.find(contact => contact.id === value) || null;

  const handleChange = e => {
    setValue(e?.target?.value ? parseInt(`${e.target.value}`) : null);
  };

  useEffect(() => {
    const actionType = new URLSearchParams(location.search).get('action_type');
    if (actionType === 'outgoing' && contactsTree.length > 0) {
      const actionTypeIndex = contactsTree.findIndex(item => item.code && item.code === 'type_outgoing_call');
      actionTypeIndex !== -1 && setValue(contactsTree[actionTypeIndex].id);
    }
  }, [location, contactsTree]);

  useEffect(() => {
    if (noAnswer && contactsTree.length > 0) {
      const actionTypeIndex = contactsTree.findIndex(
        item => item.code && item.code === (noAnswerType === 'phone' ? 'type_outgoing_call' : 'type_outgoing_whatsapp'),
      );
      const contactTypeIndex = contactsTree.findIndex(item => item.code && item.code === 'contact_negative');
      const contactResultIndex = contactsTree.findIndex(item => item.code && item.code === 'result_no_answer');
      actionTypeIndex !== -1 && setValue(contactsTree[actionTypeIndex].id);
      contactTypeIndex !== -1 && setValue(contactsTree[contactTypeIndex].id);
      contactResultIndex !== -1 && setValue(contactsTree[contactResultIndex].id);
    }
  }, [noAnswer, contactsTree, noAnswerType]);

  useEffect(() => {
    const valueContact = contactsTree.find(contact => contact.id === value) || null;

    if (value !== null && valueContact !== null && valueContact.children.length === 0) {
      onChange(valueContact);
    } else {
      onChange(null);
    }

    onStepChange(valueContact);
    // eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    if (contactsTree.length && contactsTree[0].type_name === 'Call type' && clickToCallPhone) {
      const value = contactsTree.find(contact => contact.value_name === 'Outgoing');
      if (value) {
        setValue(value.id);
        setDisabled(true);
      }
    }
  }, [contactsTree, clickToCallPhone]);

  if (contactsTree.length === 0) {
    if (depth === 0) {
      return (
        <FormControl className={classes.formControl} variant="filled" size="small">
          <TextField
            variant="filled"
            size="small"
            label="Loading..."
            InputProps={{
              endAdornment: <CircularProgress size={20} />,
            }}
          />
        </FormControl>
      );
    } else {
      return null;
    }
  }

  let fixedErrorText = error;

  if (error === 'Contact is required') {
    fixedErrorText = `${contactsTree[0].type_name} is required`;
  }

  return (
    <>
      <FormControl className={classes.formControl} variant="filled" size="small">
        <InputLabel id={`collection-call-contact-${depth}-select-label`}>
          {contactsTree[0].type_name === 'Call type' ? 'Action type' : contactsTree[0].type_name}
        </InputLabel>
        <Select
          labelId={`collection-call-contact-${depth}-select-label`}
          onChange={handleChange}
          value={value || ''}
          error={contact === null && !!error}
          disabled={disabled}>
          {contactsTree
            .filter(
              contact =>
                (contactsTree[0].type_name === 'Call type' && phoneNumber?.includes('@')
                  ? contact.value_name.includes('Email')
                  : !contact.value_name.includes('Email')) && contact.code !== 'type_whatsapp_click',
            )
            .map(contact => (
              <MenuItem
                key={contact.id}
                value={contact.id}
                disabled={userHasActivePromise && isPromiseOfPayment(contact.value_name)}>
                {contact.value_name}{' '}
                {userHasActivePromise && isPromiseOfPayment(contact.value_name) && '(has active promise)'}
              </MenuItem>
            ))}
        </Select>

        {contact === null && error && <FormHelperText error>{fixedErrorText}</FormHelperText>}
      </FormControl>

      {contact !== null && (
        <CollectionCallFormDialogTypeRecursiveInput
          contactsTree={contact.children}
          onChange={onChange}
          onStepChange={onStepChange}
          error={error}
          depth={depth + 1}
          userHasActivePromise={userHasActivePromise}
          noAnswer={noAnswer}
          noAnswerType={noAnswerType}
        />
      )}
    </>
  );
};

CollectionCallFormDialogTypeRecursiveInput.propTypes = {
  contactsTree: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      type_value: PropTypes.number.isRequired,
      type_name: PropTypes.string.isRequired,
      value_name: PropTypes.string.isRequired,
      children: PropTypes.array,
    }),
  ),
  onChange: PropTypes.func,
  onStepChange: PropTypes.func,
  error: PropTypes.string,
  depth: PropTypes.number,
  userHasActivePromise: PropTypes.bool,
  clickToCallPhone: PropTypes.string,
  phoneNumber: PropTypes.string,
  noAnswer: PropTypes.bool,
  noAnswerType: PropTypes.string,
};

CollectionCallFormDialogTypeRecursiveInput.defaultProps = {
  depth: 0,
  error: null,
  onStepChange: noop,
};

const CalculationDetails = ({ payments }) => {
  const classes = useStyles();
  return (
    <Accordion className={classes.withoutShadows}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography variant="body2">Show detailed calculation</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.p0}>
        <Table size="small">
          <TableBody>
            {payments.map(row => (
              <TableRow key={row.type}>
                <TableCell align="left">{row.type}</TableCell>
                <TableCell align="right">{row.amount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  );
};

CalculationDetails.propTypes = {
  payments: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      amount: PropTypes.string.isRequired,
    }),
  ),
};

export const CollectionCallFormDialog = ({ userId, loanId, isOpened, onClose, onSubmit, clickToCallPhone }) => {
  const [loans, setLoans] = useState([]);
  const [userHasActivePromise, setUserHasActivePromise] = useState(false);
  const [userPhoneBooks, setUserPhoneBooks] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [loan, setLoan] = useState(loanId);
  const [groupId, setGroupId] = useState(null);
  const [contact, setContact] = useState(null);
  const [contactStep, setContactStep] = useState(null);
  const [motivator, setMotivator] = useState(null);
  const [noPaymentReason, setNoPaymentReason] = useState(null);
  const [comment, setComment] = useState(null);
  const [promiseAmount, setPromiseAmount] = useState('');
  const [promiseDatetime, setPromiseDatetime] = useState(new Date());
  const [errors, setErrors] = useState({});
  const [withPromise, setWithPromise] = useState(false);
  const [contactsTree, setContactsTree] = useState([]);
  const [loading, setLoading] = useState(false);
  const [extensionDetails, setExtensionDetails] = useState(null);
  const [fullPaymentDetails, setFullPaymentDetails] = useState(null);
  const [reminderDate, setReminderDate] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [noAnswer, setNoAnswer] = useState(false);
  const [noAnswerType, setNoAnswerType] = useState(null);
  const [currentCollectorLoans, setCurrentCollectorLoans] = useState([]);
  const [currentCollectorLoansQueue, setCurrentCollectorLoansQueue] = useState([]);

  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { permissions = [] } = usePermissions();
  const location = useLocation();
  const redirect = useRedirect();

  const { data: groups } = useCollectionGroups();
  const { data: motivators } = useHandbook('collection_motivators', { items_per_page: 1000 });
  const { data: noPaymentReasons } = useHandbook('collection_no_payment_reasons', { items_per_page: 1000 });
  const { data: phoneTypes } = useHandbook('phone_types');

  const phoneTypeMap = Object.fromEntries(phoneTypes.map(phoneType => [phoneType.code, phoneType]));
  const groupMotivators = motivators
    .filter(item => item.parent === null)
    .map(parent => ({
      id: parent.id,
      parent: parent.parent,
      name: parent.name,
      enabled: parent.enabled,
      children: motivators
        .filter(item => item.parent === parent.id)
        .map(child => ({
          id: child.id,
          parent: child.parent,
          name: child.name,
          enabled: child.enabled,
        })),
    }));
  const filledGroupMotivators = groupMotivators.filter(({ children }) => children.length > 0);
  const shouldSelectMotivator = Array.isArray(contact?.motivators) && contact.motivators.length > 0;
  const shouldSelectNoPaymentReason =
    Array.isArray(contact?.no_payment_reasons) && contact.no_payment_reasons.length > 0;
  const loanIds = loans.map(({ id }) => id);
  const selectedGroup = groupId ? groups.find(group => group.id === groupId) : null;
  const maxDate = new Date();
  const currentDpd = loans.find(l => l.id === loan)?.days_past_due;
  const dpdDelta = selectedGroup?.dpd_to - currentDpd;
  const maxDateShift = dpdDelta >= selectedGroup?.ptp_term ? selectedGroup?.ptp_term : dpdDelta;
  maxDate.setDate(maxDate.getDate() + maxDateShift);
  const fullPaymentSelected = contactStep?.value_name.includes('Full payment');
  const prolongationSelected = contactStep?.value_name.includes('Prolongation');
  const protectedFields = fullPaymentSelected || prolongationSelected;
  const canEditPromiseAmount = protectedFields ? permissions.includes('CAN_PROMISE_AMOUNT_EDIT') : true;
  const filteredCollectorQueue = currentCollectorLoans
    .filter(l => !currentCollectorLoansQueue.includes(l))
    .filter(l => l !== loanId);
  const disabledGoToNextLoanBtn = filteredCollectorQueue.length === 0;

  useEffect(() => {
    const currentCollectorLoansQueue = JSON.parse(localStorage.getItem('current_collection_loans_queue'));
    currentCollectorLoansQueue?.length > 0
      ? setCurrentCollectorLoansQueue(currentCollectorLoansQueue)
      : setCurrentCollectorLoansQueue([]);
  }, []);

  useEffect(() => {
    if (isOpened) {
      setContact(null);
      setContactStep(null);
      setMotivator(null);
      setNoPaymentReason(null);
      setComment(null);
      setPromiseAmount(null);
      setPromiseDatetime(null);
      setWithPromise(false);
      setErrors({});
      setReminderDate(null);

      if (!loan) {
        setContactsTree([]);
      }
    }
  }, [isOpened, loan]);

  useEffect(() => {
    const actionPhoneNumber = new URLSearchParams(location.search).get('action_phone_number');

    if (userId) {
      dataProvider.getOne('users', { id: userId }).then(({ data }) => {
        setUserEmail(data.email || null);
        !actionPhoneNumber && setPhoneNumber(data.phone_number ?? null);
        setUserHasActivePromise(!!data.active_promise_amount);
      });

      dataProvider
        .getList('phone_books', {
          filter: { 'user.id': userId },
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'id', order: 'DESC' },
        })
        .then(({ data }) => {
          const existingPhoneNumbers = [];
          const uniquePhoneBooks = data.filter(phoneBook => {
            const exists = existingPhoneNumbers.includes(phoneBook.phone_number);

            existingPhoneNumbers.push(phoneBook.phone_number);

            return !exists;
          });
          if (clickToCallPhone) {
            setPhoneNumber(clickToCallPhone);
          } else if (actionPhoneNumber && uniquePhoneBooks.length > 0) {
            const actionPhoneNumberIndex = uniquePhoneBooks.findIndex(item => item.phone_number === actionPhoneNumber);
            actionPhoneNumberIndex !== -1 && setPhoneNumber(uniquePhoneBooks[actionPhoneNumberIndex].phone_number);
          }
          setUserPhoneBooks(uniquePhoneBooks);
        });
    }
  }, [dataProvider, userId, clickToCallPhone, location]);

  useEffect(() => {
    setContactsTree([]);
    setContact(null);
    setMotivator(null);
    setNoPaymentReason(null);
    setWithPromise(false);

    if (groupId) {
      dataProvider
        .query(`collection_contacts/tree-by-group/${groupId}`, { method: 'GET' })
        .then(({ data }) => setContactsTree(Array.isArray(data) ? data : Object.values(data)));
    }
  }, [dataProvider, groupId]);

  useEffect(() => {
    setMotivator(null);
    setNoPaymentReason(null);
  }, [contact]);

  useEffect(() => {
    if (contactStep?.type_name === 'Contact result') {
      setMotivator(null);
      setNoPaymentReason(null);
      setPromiseAmount(null);
      setPromiseDatetime(null);
    }
  }, [contactStep]);

  useEffect(() => {
    dataProvider
      .getList('loans', {
        filter: { 'user.id': userId },
        pagination: { page: 1, perPage: 30 },
        sort: { field: 'id', order: 'DESC' },
      })
      .then(({ data }) => {
        setLoans(data);

        if (!loanId && data.length > 0) {
          setLoan(data[0].id);
          setGroupId(data[0].collection_group_id);
        }
        if (loanId && data.length > 0) {
          const collectionGroupId = data.find(({ id }) => id === loanId)?.collection_group_id;
          setGroupId(collectionGroupId);
        }
      });
    dataProvider
      .query('loans/current_collector_loans', { method: 'GET' })
      .then(({ data }) => setCurrentCollectorLoans(data));
  }, [dataProvider, loanId, userId]);

  useEffect(() => {
    function foundContactTreeElement(tree, id) {
      if (!id) {
        return null;
      }

      if (!Array.isArray(tree)) {
        return null;
      }

      for (const el of tree) {
        if (el.id === id) {
          return el;
        }

        const foundChild = foundContactTreeElement(el.children, id);

        if (foundChild) {
          return foundChild;
        }
      }

      return null;
    }

    if (!contactStep) {
      setWithPromise(false);

      return;
    }

    let contactChain = [contactStep];

    while (contactChain[0].parent) {
      const foundParent = foundContactTreeElement(contactsTree, contactChain[0].parent);

      if (!foundParent) {
        break;
      }

      contactChain = [foundParent, ...contactChain];
    }

    const contactWithPromiseValue = contactChain.find(contact => isPromiseOfPayment(contact.value_name)) || null;

    setWithPromise(contactWithPromiseValue !== null);
  }, [contactsTree, contactStep]);

  const removeError = (...fields) => {
    setErrors(Object.fromEntries(Object.entries(errors).filter(([name]) => fields.includes(name) === false)));
  };

  const handleChangePhoneNumber = e => {
    removeError('phoneNumber');

    setPhoneNumber(e.target.value || null);
  };

  const handleChangeLoan = e => {
    removeError('loan');

    setLoan(e.target.value ? parseInt(`${e.target.value}`) : null);
  };

  const handleChangeContact = contact => {
    removeError('contact');

    setContact(contact);
  };

  const handleChangeContactStep = contactStep => {
    if (contactStep?.type_name === 'Promise type') {
      setPromiseAmount(null);
      setPromiseDatetime(null);
      setExtensionDetails(null);
      setFullPaymentDetails(null);
    }
    setContactStep(contactStep);
  };

  const handleChangeMotivator = e => {
    removeError('motivator');

    setMotivator(e.target.value ? parseInt(`${e.target.value}`) : null);
  };

  const handleChangeNoPaymentReason = e => {
    removeError('noPaymentReason');

    setNoPaymentReason(e.target.value ? parseInt(`${e.target.value}`) : null);
  };

  const handleChangeComment = e => {
    removeError('comment');

    setComment(e.target.value || null);
  };

  const handleChangePromiseAmount = e => {
    removeError('promiseAmount');

    setPromiseAmount(e.target.value ? parseFloat(`${e.target.value}`) : null);
  };

  const handleChangePromiseDatetime = date => {
    if (date) {
      fullPaymentSelected || prolongationSelected
        ? removeError('promiseDatetime', 'promiseAmount')
        : removeError('promiseDatetime');

      setPromiseDatetime(timezone.unshift(date));

      dataProvider
        .query(`loans/calculate_promise_payments?loan_id=${loan}&promise_date=${date.toJSON()}`, { method: 'GET' })
        .then(({ data }) => {
          setExtensionDetails(data.data.extension);
          setFullPaymentDetails(data.data.full_payment);
          let promiseAmount = null;
          if (fullPaymentSelected) {
            promiseAmount = data.data.full_payment.total;
          }
          if (prolongationSelected) {
            promiseAmount = data.data.extension.total;
          }
          setPromiseAmount(promiseAmount);
        })
        .catch(error => {
          notify(`Error: ${error.message}`, 'error');
        });
    } else {
      setPromiseDatetime(null);
      setPromiseAmount(null);
      setExtensionDetails(null);
      setFullPaymentDetails(null);
    }
  };

  const handleChangeReminderDate = date => {
    removeError('reminderDate');
    setReminderDate(date ? timezone.unshift(date) : null);
  };

  const validate = () => {
    const formErrors = {};

    if (!phoneNumber) {
      formErrors.phoneNumber = 'Phone number is required';
    }

    if (!loan) {
      formErrors.loan = 'Loan is required';
    }

    if (!groupId) {
      formErrors.groupId = 'Group is required';
    }

    if (!contact) {
      formErrors.contact = 'Contact is required';
    }

    if (!comment) {
      formErrors.comment = 'Comment is required';
    }

    if (shouldSelectMotivator) {
      if (!motivator) {
        formErrors.motivator = 'Motivator is required';
      }
    }

    if (withPromise) {
      if (!promiseAmount) {
        formErrors.promiseAmount = 'Amount is required';
      } else if (promiseAmount < 0) {
        formErrors.promiseAmount = 'Amount should be positive';
      }

      if (!promiseDatetime) {
        formErrors.promiseDatetime = 'Date is required';
      }
    }

    const isValid = Object.keys(formErrors).length === 0;

    setErrors(formErrors);

    return isValid;
  };

  const handleSubmit = e => {
    e.preventDefault();
    const clickedButton = e.nativeEvent.submitter;

    if (validate()) {
      setLoading(true);
      const phoneBook = userPhoneBooks.find(phoneBook => phoneBook.phone_number === phoneNumber);
      const data = {
        loan,
        contact: contact?.id,
        motivator,
        noPaymentReason,
        comment: (comment || '').trim() || null,
        promise_date: promiseDatetime ?? null,
        promise_amount: promiseAmount,
        reminder_date: reminderDate,
      };
      if (phoneNumber.includes('@')) {
        data.email = phoneNumber;
      } else {
        data.phone_number = phoneNumber;
        data.phone_type = phoneBook?.type;
      }

      dataProvider
        .query('collection_contact_calls', { method: 'POST', body: JSON.stringify(data) })
        .then(() => {
          if (clickedButton.name === 'submit_and_go') {
            const collectorLoansQueue = [...new Set([...currentCollectorLoansQueue, loanId])];
            setCurrentCollectorLoansQueue(collectorLoansQueue);
            localStorage.setItem('current_collection_loans_queue', JSON.stringify(collectorLoansQueue));
            const filteredCollectorQueue = currentCollectorLoans.filter(l => !collectorLoansQueue.includes(l));
            setLoading(false);
            if (filteredCollectorQueue.length > 0) {
              redirect(`/loans/${filteredCollectorQueue[0]}/show`);
            } else {
              notify('New action is successfully added. No more assigned loans left', 'success');
              onSubmit();
            }
          } else {
            setLoading(false);
            notify('New action is successfully added', 'success');
            onSubmit();
          }
        })
        .catch(error => {
          setLoading(false);
          notify(`Error: ${error.message}`, 'error');
        });
    }
  };

  const renderMotivatorOptions = availableMotivators => {
    const availableParentMotivators = new Set();

    for (const motivator of motivators) {
      if (availableMotivators.includes(motivator.id) && motivator.parent) {
        availableParentMotivators.add(motivator.parent);
      }
    }

    const motivatorOptions = [];

    for (const filledGroupMotivator of filledGroupMotivators) {
      if (availableParentMotivators.has(filledGroupMotivator.id) === false) {
        continue;
      }

      motivatorOptions.push(
        <ListSubheader disableSticky key={filledGroupMotivator.id}>
          {filledGroupMotivator.name}
        </ListSubheader>,
      );

      for (const child of filledGroupMotivator.children) {
        if (availableMotivators.includes(child.id) === false) {
          continue;
        }

        motivatorOptions.push(
          <MenuItem key={child.id} value={child.id}>
            <ListItemText>{child.name}</ListItemText>
          </MenuItem>,
        );
      }
    }

    return motivatorOptions;
  };

  const renderNoPaymentReasonOptions = availableNoPaymentReasons => {
    const noPaymentReasonOptions = [];

    for (const filledNoPaymentReason of noPaymentReasons) {
      if (filledNoPaymentReason.enabled && availableNoPaymentReasons.includes(filledNoPaymentReason.id)) {
        noPaymentReasonOptions.push(
          <MenuItem key={filledNoPaymentReason.id} value={filledNoPaymentReason.id}>
            <ListItemText>{filledNoPaymentReason.name}</ListItemText>
          </MenuItem>,
        );
      }
    }

    return noPaymentReasonOptions;
  };

  const handleOnClose = (e, eventName) => {
    if (eventName === 'backdropClick') {
      return;
    }

    onClose();
  };

  const handleNoAnswer = type => {
    setNoAnswer(true);
    setNoAnswerType(type);
  };

  return (
    <DraggableDialog
      open={isOpened}
      fullWidth
      maxWidth="sm"
      onClose={handleOnClose}
      aria-labelledby="collection-call-form-dialog-title">
      <form onSubmit={handleSubmit}>
        <DialogTitle
          id="collection-call-form-dialog-title"
          className={`${classes.modalHorizontalPadding} ${classes.modalTitle}`}>
          Create new collection call
        </DialogTitle>
        <DialogContent style={{ minHeight: 420, width: 'inherit' }} className={classes.modalHorizontalPadding}>
          <FormControl className={classes.formControl} variant="filled" size="small">
            <InputLabel id="collection-call-phone-number-select-label">Phone number or Email</InputLabel>
            <Select
              labelId="collection-call-phone-number-select-input"
              value={phoneNumber || ''}
              onChange={handleChangePhoneNumber}
              error={!!errors.phoneNumber}
              disabled={Boolean(clickToCallPhone)}>
              {userPhoneBooks.map(({ id, phone_number, type }) => (
                <MenuItem key={id} value={phone_number}>
                  <div>
                    <b>{phoneTypeMap[type]?.name || type}</b>
                    <br />
                    <span>{formatPhoneNumber(phone_number)}</span>
                  </div>
                </MenuItem>
              ))}
              {userEmail && (
                <MenuItem key={userEmail} value={userEmail}>
                  <div>
                    <b>Email</b>
                    <br />
                    <span>{userEmail}</span>
                  </div>
                </MenuItem>
              )}
            </Select>
            {errors.loan && <FormHelperText error>{errors.loan}</FormHelperText>}
          </FormControl>

          {!loanId || !loanIds.includes(loanId) ? (
            <FormControl className={classes.formControl} variant="filled" size="small">
              <InputLabel id="collection-call-loan-id-select-label">Loan</InputLabel>
              <Select
                labelId="collection-call-loan-id-select-input"
                value={loan || ''}
                onChange={handleChangeLoan}
                error={!!errors.loan}>
                {loans.map(loan => (
                  <MenuItem key={loan.id} value={loan.id}>
                    #{loan.id} {loan.state}, created {formatDate(loan.created_at)}, {loan.principal}$, {loan.tenor} days
                  </MenuItem>
                ))}
              </Select>
              {errors.loan && <FormHelperText error>{errors.loan}</FormHelperText>}
            </FormControl>
          ) : null}

          <CollectionCallFormDialogTypeRecursiveInput
            contactsTree={contactsTree}
            onChange={handleChangeContact}
            onStepChange={handleChangeContactStep}
            error={errors.contact}
            userHasActivePromise={userHasActivePromise}
            phoneNumber={phoneNumber}
            clickToCallPhone={clickToCallPhone}
            noAnswer={noAnswer}
            noAnswerType={noAnswerType}
          />

          {shouldSelectNoPaymentReason && (
            <FormControl className={classes.formControl} variant="filled" size="small">
              <InputLabel id="collection-call-group-noPaymentReason-label">Reason for not paying</InputLabel>

              <Select
                id="collection-call-group-noPaymentReason-input"
                margin="dense"
                value={noPaymentReason || ''}
                fullWidth
                onChange={handleChangeNoPaymentReason}
                error={!!errors.noPaymentReason}>
                {renderNoPaymentReasonOptions(contact.no_payment_reasons)}
              </Select>

              {errors.noPaymentReason && <FormHelperText error>{errors.noPaymentReason}</FormHelperText>}
            </FormControl>
          )}

          {shouldSelectMotivator && (
            <FormControl className={classes.formControl} variant="filled" size="small">
              <InputLabel id="collection-call-group-motivator-label">Motivator</InputLabel>

              <Select
                id="collection-call-group-motivator-input"
                margin="dense"
                value={motivator || ''}
                fullWidth
                onChange={handleChangeMotivator}
                error={!!errors.motivator}>
                {renderMotivatorOptions(contact.motivators)}
              </Select>

              {errors.motivator && <FormHelperText error>{errors.motivator}</FormHelperText>}
            </FormControl>
          )}

          {withPromise && (
            <>
              <div className={classes.formControl}>
                <DateTimePicker
                  label="Promise date"
                  inputVariant="filled"
                  closeOnSelect
                  disablePast
                  maxDate={maxDate}
                  clearable
                  value={promiseDatetime ? timezone.shift(promiseDatetime) : null}
                  onChange={handleChangePromiseDatetime}
                  error={!!errors.promiseDatetime}
                  helperText={errors.promiseDatetime}
                  size="small"
                  fullWidth
                />
              </div>
              <div className={classes.formControl}>
                <TextField
                  type="number"
                  variant="filled"
                  label="Promise amount, MX$"
                  value={promiseAmount ?? ''}
                  onChange={handleChangePromiseAmount}
                  error={!!errors.promiseAmount}
                  helperText={errors.promiseAmount}
                  size="small"
                  fullWidth
                  disabled={!canEditPromiseAmount}
                />
              </div>
              {promiseAmount && (
                <div className={classes.formControl}>
                  {prolongationSelected && <CalculationDetails payments={extensionDetails.payments} />}
                  {fullPaymentSelected && <CalculationDetails payments={fullPaymentDetails.payments} />}
                </div>
              )}
            </>
          )}

          <TextField
            id="collection-call-group-comment-input"
            className={classes.formControl}
            variant="filled"
            label="Comment"
            multiline
            value={comment || ''}
            onChange={handleChangeComment}
            error={!!errors.comment}
            helperText={errors.comment || 'This field is required'}
            size="small"
          />
          <div className={classes.formControl}>
            <DateTimePicker
              label="Reminder date"
              inputVariant="filled"
              closeOnSelect
              disablePast
              maxDate={maxDate}
              clearable
              value={reminderDate ? timezone.shift(reminderDate) : null}
              onChange={handleChangeReminderDate}
              error={!!errors.reminderDate}
              helperText={errors.reminderDate}
              size="small"
              fullWidth
            />
          </div>
        </DialogContent>

        <DialogActions className={[classes.modalHorizontalPadding, classes.flexColumn].join(' ')}>
          <Box>
            <IconButton onClick={() => handleNoAnswer('phone')}>
              <PhoneDisabledIcon />
            </IconButton>
            <IconButton onClick={() => handleNoAnswer('whatsapp')}>
              <WhatsAppIcon />
            </IconButton>
          </Box>
          <Box>
            {loading && <CircularProgress size={20} />}
            <span className={classes.colorTransparent}>{selectedGroup?.code || 'UN'}</span>

            <Button onClick={handleOnClose} color="primary">
              Cancel
            </Button>

            <Button type="submit" name="submit_only" color="primary">
              Submit
            </Button>
            <Button type="submit" name="submit_and_go" color="primary" disabled={disabledGoToNextLoanBtn}>
              Submit & go to next loan
            </Button>
          </Box>
        </DialogActions>
      </form>
    </DraggableDialog>
  );
};

CollectionCallFormDialog.propTypes = {
  userId: PropTypes.number.isRequired,
  loanId: PropTypes.number,
  isOpened: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  clickToCallPhone: PropTypes.string,
};

CollectionCallFormDialog.propTypes = {
  onClose: noop,
  onSubmit: noop,
};
